import React from 'react';
import GoogleMapReact from 'google-map-react';

import GoogleMarker from '../components/Icons/GoogleMarker';

import { GOOGLE_MAP_API_KEY } from '../constants';

const GoogleMap = ({ coordinates }) => (
  <div style={{ height: '300px' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
      defaultCenter={{
        lat: coordinates.latitude,
        lng: coordinates.longitude
      }}
      defaultZoom={16}
    >
      <GoogleMarker lat={coordinates.latitude} lng={coordinates.longitude} />
    </GoogleMapReact>
  </div>
);

export default GoogleMap;
