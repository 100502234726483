import React from 'react';

const mapMarker = '/images/icons/map-marker.svg';

const GoogleMarker = () => (
  <div style={{ width: '25px', height: '25px' }}>
    <img src={mapMarker} alt="Marker" />
  </div>
);

export default GoogleMarker;
