import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'react-markdown';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import GoogleMap from '../components/GoogleMap';
import Form from '../components/Form';

import IconFB from '../components/Icons/facebook';
import IconTwitter from '../components/Icons/twitter';
import IconMailSm from '../components/Icons/mail-sm';
import {
  IconVideo,
  IconText,
  IconAudio,
  IconVeranstaltung,
  IconSchulung
} from '../components/Icons';

import { BVOG_URL } from '../constants';
import { concateneteHashtags, getDateAndTime } from '../utils/common';

const Hashtags = ({ hashtag, themenschwerpunkt, oesterreichbilanz, arbeitsweise }) => (
  <div className="tags">
    {hashtag.split(',').map((tag, index) => (
      <Link key={index} to={`/search?query=${tag.trim()}`} state={{ searchQuery: tag.trim() }}>
        <div>#{tag.trim()}</div>
      </Link>
    ))}
    {themenschwerpunkt.map((tag, index) => (
      <Link
        key={index}
        to={`/search?query=${tag.link.trim()}`}
        state={{ searchQuery: tag.link.trim() }}
      >
        <div>#{tag.link}</div>
      </Link>
    ))}
    {oesterreichbilanz.map((tag, index) => (
      <Link
        key={index}
        to={`/search?query=${tag.link.trim()}`}
        state={{ searchQuery: tag.link.trim() }}
      >
        <div>#{tag.link}</div>
      </Link>
    ))}
    {arbeitsweise.map((tag, index) => (
      <Link
        key={index}
        to={`/search?query=${tag.link.trim()}`}
        state={{ searchQuery: tag.link.trim() }}
      >
        <div>#{tag.link}</div>
      </Link>
    ))}
  </div>
);

const renderContent = content => {
  switch (content.__typename) {
    case 'DatoCmsModText':
      return <div dangerouslySetInnerHTML={{ __html: content.modTextText }} />;

    case 'DatoCmsModPicture':
      return content.modPicturePicture ? (
        <img
          src={content.modPicturePicture.url}
          alt={content.modPicturePicture.title || content.modPicturePicture.alt || ''}
        />
      ) : null;

    case 'DatoCmsModVideo':
      return content.modVideoVideo ? (
        <ReactPlayer
          url={content.modVideoVideo.url}
          controls
          style={{ marginBottom: '16px', minHeight: '360px' }}
        />
      ) : null;

    case 'DatoCmsModPodcast':
      return content.modPodcastPodcast ? (
        <ReactPlayer url={content.modPodcastPodcast} style={{ marginBottom: '16px' }} />
      ) : null;

    default:
      console.log('Error in renderContent');
  }
};

const Event = ({ data }) => {
  const event = data.allDatoCmsVeranstaltungen.edges[0].node;

  /* SEO */
  const seoTitle = (event.seo && event.seo.title) || event.beitragsname;
  const seoDescription =
    (event.seo && event.seo.description) ||
    concateneteHashtags(
      event.hashtag,
      event.themenschwerpunkt,
      event.oesterreichbilanz,
      event.arbeitsweise
    );

  return (
    <Layout
      title={seoTitle}
      description={seoDescription}
      twitterCard={event.seo && event.seo.twitterCard}
      imageURL={event.seo && event.seo.image && event.seo.image.path}
    >
      <div className="event common-page article-page">
        <Subheader title={`VERANSTALTUNGEN: ${event.beitragsname}`} />

        <div className="article-feed">
          <article className="article">
            <div className="container">
              <div className="post-page__wrapper">
                <div className="post-page__social">
                  <div>
                    <FacebookShareButton url={`${BVOG_URL}/veranstaltungen/${event.link}`}>
                      <IconFB />
                    </FacebookShareButton>
                  </div>
                  <div>
                    <TwitterShareButton url={`${BVOG_URL}/veranstaltungen/${event.link}`}>
                      <IconTwitter />
                    </TwitterShareButton>
                  </div>
                  <div>
                    <EmailShareButton url={`${BVOG_URL}/veranstaltungen/${event.link}`}>
                      <IconMailSm />
                    </EmailShareButton>
                  </div>
                </div>
                <div className="post-page__content d-flex">
                  {event.image && (
                    <div className="post-page__content__left">
                      <div className="post-page__content__image">
                        <Img
                          fluid={event.image.fluid}
                          alt={event.beitragsname}
                          style={{ width: 250, height: 250 }}
                        />
                        <div className="post-page__content__image__top">
                          <div className="content-hashtags">
                            {event.themenschwerpunkt.map((tag, index) => (
                              <span key={index}>#{tag.link}</span>
                            ))}
                          </div>

                          <div className="content-icons">
                            {event.contentModules.some(
                              content => content.__typename === 'DatoCmsModText'
                            ) && <IconText />}
                            {event.art === 'Schulung' ? <IconSchulung /> : <IconVeranstaltung />}
                            {event.contentModules.some(
                              content => content.__typename === 'DatoCmsModVideo'
                            ) && <IconVideo />}
                            {event.contentModules.some(
                              content => content.__typename === 'DatoCmsModPodcast'
                            ) && <IconAudio />}
                          </div>
                        </div>
                      </div>
                      {event.hashtag && (
                        <Hashtags
                          hashtag={event.hashtag}
                          themenschwerpunkt={event.themenschwerpunkt}
                          oesterreichbilanz={event.oesterreichbilanz}
                          arbeitsweise={event.arbeitsweise}
                        />
                      )}
                    </div>
                  )}

                  <div className="post-page__content__right">
                    <h3 className="post-page__content__right__date">
                      {getDateAndTime(event.beginn)}
                    </h3>
                    <h3 className="post-page__content__right__location">{event.ort}</h3>
                    <h3 className="post-page__content__right__title">{event.beitragsname}</h3>

                    <div
                      className="post-page__content__frontText"
                      dangerouslySetInnerHTML={{ __html: event.frontText }}
                    />

                    <ReactMarkdown
                      className="post-page__content__text"
                      source={event.beschreibung}
                    />

                    {event.contentModules.map((content, index) => (
                      <div className="post-page__content__module" key={index}>
                        {renderContent(content)}
                      </div>
                    ))}

                    <p />
                    <p>{event.series}</p>
                    <p>{event.art}</p>

                    {event.veranstaltungsort && (
                      <p>
                        <b>Veranstaltungsort: </b>
                        {event.veranstaltungsort}
                      </p>
                    )}

                    {event.admissionAt && (
                      <p>
                        <b>Einlass ab: </b>
                        {getDateAndTime(event.admissionAt)}
                      </p>
                    )}

                    {event.beginn && (
                      <p>
                        <b>Beginn: </b>
                        {getDateAndTime(event.beginn)}
                      </p>
                    )}

                    {event.until && (
                      <p>
                        <b>Bis: </b>
                        {getDateAndTime(event.until)}
                      </p>
                    )}

                    {event.registrationEmail && (
                      <p>
                        <b>Anmeldung E-Mail:</b> {event.registrationEmail}
                      </p>
                    )}

                    {event.host && (
                      <p>
                        <b>Veranstalter: </b>
                        {event.host}
                      </p>
                    )}

                    {event.cooperationPartner && (
                      <p>
                        <b>Kooperationspartner: </b>
                        {event.cooperationPartner}
                      </p>
                    )}
                    <p>{event.beitraege && event.beitraege.link}</p>

                    {event.coordinates && <GoogleMap coordinates={event.coordinates} />}

                    {!event.image && event.hashtag && (
                      <Hashtags
                        hashtag={event.hashtag}
                        themenschwerpunkt={event.themenschwerpunkt}
                        oesterreichbilanz={event.oesterreichbilanz}
                        arbeitsweise={event.arbeitsweise}
                      />
                    )}

                    {event.beitraege.length > 0 && (
                      <div className="links">
                        <b>EMPFOHLENE BEITRÄGE AUF BVOG.AT: </b>

                        {event.beitraege.map(({ link, beitragsname }, index) => (
                          <div key={index}>
                            <Link to={`/beitraege/${link}`}>{beitragsname}</Link>
                          </div>
                        ))}
                      </div>
                    )}

                    {event.veranstaltungen.length > 0 && (
                      <div className="links">
                        <b>Veranstaltungen: </b>

                        {event.veranstaltungen.map(({ link, beitragsname }, index) => (
                          <div key={index}>
                            <Link to={`/veranstaltungen/${link}`}>{beitragsname}</Link>
                          </div>
                        ))}
                      </div>
                    )}

                    {event.contentModules.some(
                      content => content.__typename === 'DatoCmsModExternalLink'
                    ) && (
                      <div>
                        <h4 style={{ marginBottom: '1rem' }}>
                          Externe empfohlene Veranstaltungen:
                        </h4>
                        {event.contentModules
                          .filter(content => content.__typename === 'DatoCmsModExternalLink')
                          .map((content, index) => (
                            <div
                              className="post-page__content__module post-page__content__module--link"
                              key={index}
                            >
                              <a href={content.modExternalLinkUrl}>
                                {content.modExternalLinkImage && (
                                  <Img
                                    fluid={content.modExternalLinkImage.fluid}
                                    alt={content.modExternalLinkTitel || ''}
                                    style={{ width: 75, height: 75 }}
                                  />
                                )}
                                <div className="link-data">
                                  {content.modExternalLinkTitel && (
                                    <span>{content.modExternalLinkTitel}</span>
                                  )}
                                  <i>{content.modExternalLinkUrl}</i>
                                </div>
                              </a>
                            </div>
                          ))}
                      </div>
                    )}

                    {event.contactForm && (
                      <div style={{ marginTop: 15 }}>
                        <b>Anmeldeformular: </b>
                        <Form
                          subjectName={event.beitragsname}
                          recipientEmail={event.contactFormEmail}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default Event;

export const query = graphql`
  query($slug: String) {
    allDatoCmsVeranstaltungen(filter: { link: { eq: $slug } }) {
      edges {
        node {
          ...DatoVeranstaltungenFields
        }
      }
    }
  }
`;
